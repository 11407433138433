import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import CustomButton from "../../components/custom-button/custom-button.component"

export const Wrapper = styled(SectionWrapper)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 0 !important;
  }
  //padding-bottom: 10rem;
  color: white;
`

export const Title = styled.h1`
  .line {
    overflow: hidden;
  }

  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(57)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(60)};
    line-height: ${({ theme }) => theme.typography.pxToRem(69)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(80)};
    line-height: ${({ theme }) => theme.typography.pxToRem(88)};
  }
`

export const TitleWrapper = styled.div`
  visibility: hidden;
`

export const Description = styled(Typography)`
  visibility: hidden;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 32px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 536px;
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.typography.pxToRem(24)};
  position: relative;
  z-index: 5;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 700px;
    gap: ${({ theme }) => theme.typography.pxToRem(40)};
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: ${({ theme }) => theme.typography.pxToRem(32)};
  }
`

export const CustomContainer = styled(Container)`
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 2.5rem;
    padding-bottom: 8rem;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    height: calc(100vh - 80px);
    min-height: 600px;
    max-height: 900px;
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const MoleculeWrapper = styled.div`
  width: 50%;
  height: 100%;
  right: 0;
  top: -4%;
  z-index: 4;
  position: absolute;
  visibility: hidden;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    width: 90%;
    height: 100%;
    right: -24%;
    top: 71%;
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 50%;
    height: 100%;
    right: 0;
    top: 46%;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 50%;
    height: 100%;
    right: 0;
    top: -4%;
  }
`

export const MoleculeImage = styled(CustomImage)`
  img {
    object-fit: contain !important;
    object-position: left;
  }
`

export const TopMoleculeWrapper = styled.div`
  position: absolute;
  width: 45%;
  height: 40%;
  top: 7%;
  right: 19%;
  z-index: 2;
  visibility: hidden;
`

export const TopMolecule = styled(CustomImage)`
  img {
    object-fit: contain !important;
  }
`

export const BottomContainer = styled(Container)`
  position: relative;
  //z-index: 3;
`

export const BottomMolecule = styled(CustomImage)`
  img {
    object-fit: contain !important;
    transform: rotate(-36deg);
    ${({ theme }) => theme.breakpoints.down("md")} {
      transform: rotate(350deg);
    }
  }
`

export const BottomMoleculeWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  width: 75%;
  height: 200%;
  bottom: 0;
  left: 40%;
  transform: translateX(-50%);
  z-index: 2;
  ${({ theme }) => theme.breakpoints.down("md")} {
    bottom: -20%;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 184%;
    height: 200%;
    bottom: -73%;
    left: 63%;
  }
`

export const Button = styled(CustomButton)`
  padding: 0.8rem 1.75rem !important;
  min-width: 132px;
  color: #0d1827;
  font-weight: 500 !important;
`

export const CardWrapper = styled.div`
  visibility: visible;
  position: relative;
  z-index: 5;
  height: 100%;
  border-radius: 8px;
`
