import styled from "@emotion/styled"
import CustomImage from "../custom-image/custom-image.component"
import { Typography } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"

export const Wrapper = styled(CustomLink)`
  background-color: white;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 8px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.typography.pxToRem(30)};
  }
  svg {
    transition: 0.3s all ease;
  }

  &:hover {
    svg {
      stroke: white;
      fill: ${({ theme }) => theme.palette.text.content};
    }
  }
`

export const Icon = styled(CustomImage)`
  width: 32px;
  height: 32px;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: 32px;
  padding-top: 1rem;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-top: 0.5rem;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    padding-top: ${({ theme }) => theme.typography.pxToRem(20)};
  }
`

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 24px;
    max-width: 316px;
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Link = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1.5rem;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: 1rem;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: 2.75rem;
    gap: ${({ theme }) => theme.typography.pxToRem(20)};
  }
`
