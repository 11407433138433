import React from "react"
import * as S from "./news-section.styles"
import { Container, useMediaQuery, useTheme } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import CustomButton from "../../components/custom-button/custom-button.component"
import NewsCard from "../../components/news-card/news-card.component"
import { Fade } from "react-awesome-reveal"
import { Parallax } from "react-scroll-parallax"

const NewsSection = ({ news, bgImage }) => {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <S.Wrapper>
      <Fade triggerOnce delay={500}>
        <Container maxWidth="xl" sx={{ position: "relative" }}>
          <S.ImageWrapper>
            <Parallax speed={10}>
              <S.Molecule img={bgImage} alt="molecule-image" />
            </Parallax>
          </S.ImageWrapper>
          <S.TopWrapper>
            <S.Title>In the News</S.Title>
            {!isSm && <CustomButton href="/news/">View All</CustomButton>}
          </S.TopWrapper>
          {news.map(({ article }, index) => (
            <NewsCard
              className={index === news.length - 1 ? "lastNew" : ""}
              key={`news-card-${index}`}
              {...article}
            />
          ))}
          {isSm && (
            <S.BottomWrapper>
              <CustomButton href="/news/">View All</CustomButton>
            </S.BottomWrapper>
          )}
        </Container>
      </Fade>
    </S.Wrapper>
  )
}

export default NewsSection
