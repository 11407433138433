import styled from "@emotion/styled"
import CustomImage from "../custom-image/custom-image.component"
import { Typography } from "@mui/material"
import Arrow from "../../assets/arrow-right-white.svg"

export const Wrapper = styled.div`
  padding-top: 80px;
`

export const Icon = styled(CustomImage)`
  width: 32px;
  height: 32px;
  img {
    max-height: 100%;
  }
`

export const Text = styled(Typography)`
  &.title {
    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      line-height: 32px;
    }
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: 24px;
  }

  &.description {
    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
      line-height: 32px;
    }
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 24px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
`

export const WhiteArrow = styled(Arrow)`
  width: 32px;
  height: 32px;
  margin: auto 0;
`

export const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme }) => theme.breakpoints.up(740)} {
    flex-direction: row;
    justify-content: space-between;
    gap: unset;
  }
`

export const Card = styled.div`
  max-width: unset;

  ${({ theme }) => theme.breakpoints.up(740)} {
    max-width: 218px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 275px;
  }

  ${({ theme }) => theme.breakpoints.up(1090)} {
    max-width: 336px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    max-width: 378px;
  }
`
