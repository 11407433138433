import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  color: white;
`

export const Description = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: 40px;
  margin: auto;
  max-width: 550px;
  text-align: center;
  position: relative;
  z-index: 3;
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 675px;
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: 57px;
  }
`

export const Molecule = styled(CustomImage)`
  img {
    object-fit: contain !important;
  }
`

export const MoleculeWrapper = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  margin-top: -12%;
  max-width: 650px;
`
