import React from "react"
import SolutionSection from "../SolutionSection"
import CyclesSection from "../CyclesSection"
import MoleculeSection from "../MoleculeSection"

import * as S from "./about-solution.styles"

const AboutSolution = ({
  topDescription,
  bottomDescription,
  statsDescription,
  description,
  cycles,
  descriptionMolecule,
}) => {
  return (
    <S.Wrapper name={"Solution"}>
      <SolutionSection
        topDescription={topDescription}
        bottomDescription={bottomDescription}
        statsDescription={statsDescription}
      />
      <CyclesSection description={description} cycles={cycles} />
      <MoleculeSection descriptionMolecule={descriptionMolecule} />
    </S.Wrapper>
  )
}

export default AboutSolution
