import React from "react"
import * as S from "./cycles-section.styles"
import { Container } from "@mui/material"
import CyclesGrid from "../../components/cycles-grid/cycles-grid.component"

const CyclesSection = ({ description, cycles }) => {
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        {description && (
          <S.TopDescription>
            <b>Proton</b>
            <S.ProtonLogo />
            closes the full cycle by addressing the key challenges at each stage
            of the process
          </S.TopDescription>
        )}
        {cycles && <CyclesGrid cycles={cycles} />}
      </Container>
    </S.Wrapper>
  )
}

export default CyclesSection
