import React, { useEffect, useState } from "react"
import * as S from "./cycle-spin.styles"
import { isBrowser } from "../../utils/utils"
import { CustomCircle2 } from "./cycle-spin.styles"

const CycleSpin = () => {
  const [centerTitle, setCenterTitle] = useState("")
  const [showText, setShowText] = useState(false)

  useEffect(() => {
    if (isBrowser) {
      const topCircle = document.getElementById("topCircle")
      const rightCircle = document.getElementById("rightCircle")
      const leftCircle = document.getElementById("leftCircle")
      //top circle
      topCircle.addEventListener("mouseover", function (e) {
        setCenterTitle("What molecule should we make?")
        setShowText(true)
      })
      topCircle.addEventListener("mouseleave", function (e) {
        // setCenterTitle(" ")
        setShowText(false)
      })
      //rightCircle
      rightCircle.addEventListener("mouseover", function (e) {
        setCenterTitle("How do we synthesize it?")
        setShowText(true)
      })
      rightCircle.addEventListener("mouseleave", function (e) {
        // setCenterTitle(" ")
        setShowText(false)
      })
      //leftCircle
      leftCircle.addEventListener("mouseover", function (e) {
        setCenterTitle("What are its properties?")
        setShowText(true)
      })
      leftCircle.addEventListener("mouseleave", function (e) {
        // setCenterTitle(" ")
        setShowText(false)
      })
    }
  }, [])

  return (
    <S.Wrapper>
      <S.CustomCircle2 />
      {/*<S.CustomCircle />*/}
      <S.CenterWrapper>
        <S.CenterTitle
          className="top-title"
          show={centerTitle === "What molecule should we make?" && showText}
        >
          {centerTitle}
        </S.CenterTitle>
        {/*<S.ProtonLogo />*/}
        <S.CenterTitle
          show={centerTitle !== "What molecule should we make?" && showText}
        >
          {centerTitle}
        </S.CenterTitle>
      </S.CenterWrapper>
    </S.Wrapper>
  )
}

export default CycleSpin
