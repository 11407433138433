import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  color: white;
  padding: 0 !important;
`

export const CustomContainer = styled(Container)`
  position: relative;
  min-height: calc(100vh - ${({ theme }) => theme.navHeight}px);
  display: flex;
  align-items: center;
`

export const Title = styled.h1`
  max-width: 680px;
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(57)};
`

export const Molecule = styled(CustomImage)`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100vh;
  img {
    object-fit: contain !important;
    object-position: top;
  }
`

export const TopMolecule = styled(CustomImage)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 70vw;
  height: 200px;
  transform: translateX(-50%);
  img {
    object-fit: contain !important;
  }
`
