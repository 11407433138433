import React from "react"
import MiscContent from "../layouts/MiscContent"
import HomeHero from "../layouts/HomeHero"
import NewsSection from "../layouts/NewsSection"
import MeetTeam from "../layouts/MeetTeam"
import PartnersLogo from "../layouts/PartnersLogo"
import AboutHero from "../layouts/AboutHero"
import SolutionSection from "../layouts/SolutionSection"
import CyclesSection from "../layouts/CyclesSection"
import MoleculeSection from "../layouts/MoleculeSection"
import NewsHero from "../layouts/NewsHero"
import PipelineHero from "../layouts/PipelineHero"
import PipelineTable from "../layouts/PipelineTable"
import PublicationsSlider from "../layouts/PublicationsSlider"
import GridTeam from "../layouts/GridTeam"
import MapMarkers from "../layouts/MapMarkers"
import TeamHero from "../layouts/TeamHero"
import ChartsSection from "../layouts/ChartsSection"
import InfoGrid from "../layouts/InfoGrid"
import ProjectTable from "../layouts/ProjectTable"
import JoinTeam from "../layouts/JoinTeam"
import CaseStudiesHero from "../layouts/CaseStudiesHero"
import NewsPage from "../layouts/NewsPage"
import AboutProblem from "../layouts/AboutProblem/about-problem.component"
import AboutSolution from "../layouts/AboutSolution"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "HomeHero":
      return <HomeHero {...layout} />
    case "NewsSection":
      return <NewsSection {...layout} />
    case "MeetTeam":
      return <MeetTeam {...layout} />
    case "PartnersLogo":
      return <PartnersLogo {...layout} />
    case "AboutHero":
      return <AboutHero {...layout} />
    case "NewsHero":
      return <NewsHero {...layout} />
    case "PipelineHero":
      return <PipelineHero {...layout} />
    case "PipelineTable":
      return <PipelineTable {...layout} />
    case "PublicationsSlider":
      return <PublicationsSlider {...layout} />
    case "GridTeam":
      return <GridTeam {...layout} />
    case "MapMarkers":
      return <MapMarkers {...layout} />
    case "TeamHero":
      return <TeamHero {...layout} />
    case "ChartsSection":
      return <ChartsSection {...layout} />
    case "InfoGrid":
      return <InfoGrid {...layout} />
    case "ProjectTable":
      return <ProjectTable {...layout} />
    case "JoinTeam":
      return <JoinTeam {...layout} />
    case "CaseStudiesHero":
      return <CaseStudiesHero {...layout} />
    case "NewsPage":
      return <NewsPage {...layout} />
    case "AboutProblem":
      return <AboutProblem {...layout} />
    case "AboutSolution":
      return <AboutSolution {...layout} />
  }
}
