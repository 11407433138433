import React from "react"
import * as S from "./cycles-grid.styles"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@emotion/react"

const CyclesGrid = ({ cycles }) => {
  const theme = useTheme()
  const isLg = useMediaQuery(theme.breakpoints.up(1300))

  return (
    <S.Wrapper>
      <S.CustomDiv>
        {cycles.map(({ title, description, icon }, index) => (
          <React.Fragment key={`cycle-item-${index}`}>
            <S.Card>
              <S.TextWrapper>
                <S.Icon img={icon} />
                <S.Text className="title">{title}</S.Text>
                <S.Text className="description">{description}</S.Text>
              </S.TextWrapper>
            </S.Card>
            {index !== 2 && isLg ? <S.WhiteArrow /> : ""}
          </React.Fragment>
        ))}
      </S.CustomDiv>
    </S.Wrapper>
  )
}

export default CyclesGrid
