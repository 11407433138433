import React from "react"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"

import { Pie } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart = ({ percentageA, percentageB }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }
  const data = {
    labels: ["Active", "inactive"],
    machineLabels: ["active", "inactive"],
    datasets: [
      {
        borderWidth: 0,
        backgroundColor: ["#2E558A", "rgba(255, 255, 255, 0.3)"],
        data: [percentageA, percentageB],
      },
    ],
  }
  return (
    <Pie
      style={{ maxWidth: 75, maxHeight: 75 }}
      type="pie"
      data={data}
      options={options}
    />
  )
}

export default PieChart
