import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import CustomImage from "../../components/custom-image/custom-image.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: transparent;
  position: relative;
  z-index: 4;
`

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up("lg")} {
    padding-bottom: 1.75rem;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.palette.text.content};
  font-weight: 400;
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: 57px;

  ${({ theme }) => theme.breakpoints.up("lg")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(60)};
    line-height: 69px;
  }
`

export const BottomWrapper = styled.div`
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
`

export const Molecule = styled(CustomImage)`
  .gatsby-image-wrapper {
    overflow: visible;
    img {
      object-fit: contain !important;
      filter: blur(7px);
      transform: rotate(-35deg);
      ${({ theme }) => theme.breakpoints.up("md")} {
        filter: blur(3px);
        transform: rotate(-120deg);
      }
    }
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: -7%;
  left: -25%;
  width: 200px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    bottom: -20%;
    left: -13%;
    width: 200px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    bottom: -40%;
    left: -15%;
    width: 300px;
  }
  ${({ theme }) => theme.breakpoints.up("lg")} {
    bottom: -27%;
    left: -16%;
    width: 400px;
  }
`
