import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"
import SEO from "../../components/seo/seo.component";

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Pagebuilder_Layouts_NewsSection {
            ...NewsSection
          }
          ... on WpPage_Pagebuilder_Layouts_MeetTeam {
            ...MeetTeam
          }
          ... on WpPage_Pagebuilder_Layouts_PartnersLogo {
            ...PartnersLogo
          }
          ... on WpPage_Pagebuilder_Layouts_AboutHero {
            ...AboutHero
          }
          ... on WpPage_Pagebuilder_Layouts_NewsHero {
            ...NewsHero
          }
          ... on WpPage_Pagebuilder_Layouts_PipelineHero {
            ...PipelineHero
          }
          ... on WpPage_Pagebuilder_Layouts_PipelineTable {
            ...PipelineTable
          }
          ... on WpPage_Pagebuilder_Layouts_PublicationsSlider {
            ...PublicationsSlider
          }
          ... on WpPage_Pagebuilder_Layouts_GridTeam {
            ...GridTeam
          }
          ... on WpPage_Pagebuilder_Layouts_TeamHero {
            ...TeamHero
          }
          ... on WpPage_Pagebuilder_Layouts_MapMarkers {
            ...MapMarkers
          }
          ... on WpPage_Pagebuilder_Layouts_ChartsSection {
            ...ChartsSection
          }
          ... on WpPage_Pagebuilder_Layouts_InfoGrid {
            ...InfoGrid
          }
          ... on WpPage_Pagebuilder_Layouts_ProjectTable {
            ...ProjectTable
          }
          ... on WpPage_Pagebuilder_Layouts_JoinTeam {
            ...JoinTeam
          }
          ... on WpPage_Pagebuilder_Layouts_CaseStudiesHero {
            ...CaseStudiesHero
          }
          ... on WpPage_Pagebuilder_Layouts_NewsPage {
            ...NewsPage
          }
          ... on WpPage_Pagebuilder_Layouts_AboutProblem {
            ...AboutProblem
          }
          ... on WpPage_Pagebuilder_Layouts_AboutSolution {
            ...AboutSolution
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          variation
          whiteFooter
          blueFooter
          haveBlueNav
          haveElevation
        }
      }
    }
  }
`
export const Head = ({ data }) => <SEO data={data?.wpPage?.seo} />
const PageTemplate = ({ data }) => {
  const { slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
