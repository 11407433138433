import React, { useEffect } from "react"
import * as S from "./home-hero.styles"
import { Container, Grid, useMediaQuery } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import InfoCard from "../../components/info-card/info-card.component"
import { useTheme } from "@mui/system"
import { gsap } from "gsap"
import SplitTitle from "../../components/split-title/split-title.component"
import { Parallax } from "react-scroll-parallax"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isBrowser } from "../../utils/utils"

const HomeHero = ({ title, description, cards }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down("md"))
  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.to(".description", {
      autoAlpha: 1,
      duration: 0.2,
      delay: 1.4,
    })
    gsap.to(".topMolecule", {
      autoAlpha: 1,
      duration: 0.2,
      delay: 1,
      ease: "none",
    })
    gsap.to(".bottomMolecule", {
      autoAlpha: 1,
      duration: 0.2,
      delay: 1.2,
      ease: "none",
    })
    gsap.to(".bigMolecule", {
      autoAlpha: 1,
      duration: 0.4,
      delay: 1.4,
      ease: "none",
    })
    if (isBrowser) {
      if (window.screen.width < 600) {
        gsap.from(".card", {
          scrollTrigger: {
            trigger: ".cardWrapper",
            // markers: true,
            start: "0 600",
          },
          x: -100,
          autoAlpha: 0,
          duration: 0.7,
          stagger: 0.3,
        })
      } else {
        gsap.from(".card", {
          scrollTrigger: {
            trigger: ".cardWrapper",
            // markers: true,
            start: "0 600",
          },
          y: -100,
          autoAlpha: 0,
          duration: 0.7,
          stagger: 0.3,
        })
      }
    }
  }, [])

  const staticQuery = useStaticQuery(graphql`
    query {
      homeHero: file(relativePath: { eq: "common-molecule.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      molecule1: file(relativePath: { eq: "molecule-1.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      molecule2: file(relativePath: { eq: "molecule-2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth="xl">
        <S.TopMoleculeWrapper className="topMolecule">
          <Parallax speed={10}>
            {!isMd && (
              <S.TopMolecule
                img={staticQuery.molecule1}
                alt="molecule-image-3"
              />
            )}
          </Parallax>
        </S.TopMoleculeWrapper>
        <S.InfoWrapper>
          <S.TitleWrapper className="title-wrapper">
            <SplitTitle>
              <S.Title>{title}</S.Title>
            </SplitTitle>
          </S.TitleWrapper>
          <S.Description className="description">{description}</S.Description>
          <div>
            {isMd && (
              <S.Button
                target="_blank"
                href="https://app.postera.ai/manifold/"
                color={"secondary"}
              >
                Manifold
              </S.Button>
            )}
          </div>
        </S.InfoWrapper>
        <S.MoleculeWrapper className="bigMolecule">
          <Parallax speed={-8}>
            <S.MoleculeImage img={staticQuery.homeHero} alt="molecule-image" />
          </Parallax>
        </S.MoleculeWrapper>
      </S.CustomContainer>
      <S.BottomContainer className="cardWrapper">
        <S.BottomMoleculeWrapper className="bottomMolecule">
          <Parallax speed={10}>
            <S.BottomMolecule
              img={staticQuery.molecule2}
              alt="molecule-image-2"
            />
          </Parallax>
        </S.BottomMoleculeWrapper>
        <Grid
          container
          spacing={{ md: 2, sm: 1, xs: 2 }}
          justifyContent="center"
        >
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} key={`card-${index}`}>
              <S.CardWrapper className="card">
                <InfoCard className="card" {...card} />
              </S.CardWrapper>
            </Grid>
          ))}
        </Grid>
      </S.BottomContainer>
    </S.Wrapper>
  )
}

export default HomeHero
