import React from "react"
import * as S from "./news-hero.styles"
import { graphql, useStaticQuery } from "gatsby"

const NewsHero = ({ title }) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      molecule: file(relativePath: { eq: "news-hero-molecule.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      topMolecule: file(relativePath: { eq: "top-molecule-news.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Wrapper>
      <S.CustomContainer maxWidth="xl">
        <S.TopMolecule img={staticQuery.topMolecule} />
        <S.Molecule img={staticQuery.molecule} />
        <S.Title>{title}</S.Title>
      </S.CustomContainer>
    </S.Wrapper>
  )
}

export default NewsHero
