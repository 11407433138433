import React, { useEffect, useState } from "react"
import * as S from "./solution-section.styles"
import { Container } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import CycleSpin from "../../components/cycle-spin/cycle-spin.component"
import PieChart from "../../components/pie-chart/pie-chart.component"
import { isBrowser } from "../../utils/utils"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const SolutionSection = ({
  topDescription,
  bottomDescription,
  statsDescription,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger)
    }
    ScrollTrigger.create({
      trigger: ".pieTrigger",
      // markers: true,
      onEnter: () => setIsVisible(true),
    })
  }, [])
  const pieData = [
    {
      percentage: "7%",
      title: "Design",
      percentageA: 7,
      percentageB: 93,
    },
    {
      percentage: "78%",
      title: "Make",
      percentageA: 78,
      percentageB: 22,
    },
    {
      percentage: "15%",
      title: "Test",
      percentageA: 15,
      percentageB: 85,
    },
  ]
  const staticQuery = useStaticQuery(graphql`
    query {
      circleImg: file(relativePath: { eq: "circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      stages: file(relativePath: { eq: "stages.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <S.TitleWrapper>
          <S.Line />
          <S.Title>
            The
            <br />
            Solution
          </S.Title>
        </S.TitleWrapper>
        <S.ContentWrapper>
          {topDescription && (
            <S.Text className="top">
              PostEra has developed <b>Proton</b>
              <S.ProtonLogoDark />, an end-to-end machine learning platform to
              close the Design-Make-Test cycle of Medicinal Chemistry
            </S.Text>
          )}
          {bottomDescription && (
            <S.Text className="bottom">{bottomDescription}</S.Text>
          )}
        </S.ContentWrapper>
        <S.StatsWrapper>
          {/*<S.Image img={staticQuery.circleImg} />*/}
          <S.CircleWrapper>
            <CycleSpin />
          </S.CircleWrapper>
          {statsDescription && (
            <S.Text className="stats">{statsDescription}</S.Text>
          )}
          {/*<S.Image className="stages" img={staticQuery.stages} />*/}
          <div style={{ minHeight: 135 }} className="pieTrigger">
            {isVisible && (
              <S.ChartsWrapper>
                {pieData.map(
                  ({ title, percentageA, percentage, percentageB }, index) => (
                    <S.PieWrapper key={`percentage-pie-${index}`}>
                      <span>{percentage}</span>
                      <S.ChartCircleWrapper>
                        <PieChart
                          percentageA={percentageA}
                          percentageB={percentageB}
                        />
                      </S.ChartCircleWrapper>
                      <span>{title}</span>
                    </S.PieWrapper>
                  )
                )}
              </S.ChartsWrapper>
            )}
          </div>
        </S.StatsWrapper>
      </Container>
    </S.Wrapper>
  )
}

export default SolutionSection
