import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import ProtonDark from "../../assets/proton-logo-dark.svg"

export const Wrapper = styled(SectionWrapper)``

export const Title = styled(Typography)`
  text-transform: uppercase;
  font-weight: 600;
  line-height: 24px;
`

export const Line = styled.div`
  width: 2px;
  height: auto;
  background: linear-gradient(101.12deg, #3c79cc 0%, #a506ef 83.58%);
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 20px;
`

export const ContentWrapper = styled.div`
  padding-top: 8px;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: ${({ theme }) => theme.palette.text.content};
`

export const Text = styled(Typography)`
  &.top {
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: 40px;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(48)};
      line-height: 58px;
    }

    b {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  &.bottom {
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 24px;

    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(18)};
      line-height: 32px;
    }
  }

  &.stats {
    max-width: 675px;
    text-align: center;
    margin: auto;
    color: #0d1827;

    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: 24px;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(24)};
      line-height: 32px;
    }

    padding-top: ${({ theme }) => theme.typography.pxToRem(80)};
    padding-bottom: ${({ theme }) => theme.typography.pxToRem(40)};
  }
`

export const Image = styled(CustomImage)`
  max-width: 470px;
  height: 395px;
  margin: auto;

  &.stages {
    max-width: 575px;
    height: 150px;
  }
  img {
    object-fit: contain !important;
  }
`

export const StatsWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.typography.pxToRem(40)};
`

export const CircleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ChartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
`

export const PieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: white;
  span {
    font-size: ${({ theme }) => theme.typography.pxToRem(18)};
    line-height: 23px;
  }
`

export const ChartCircleWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  height: 75px;
  width: 75px;
`
export const ProtonLogoDark = styled(ProtonDark)`
  width: 40px;
  height: 40px;
  margin-left: 0.3rem;
  margin-right: 0.5rem;

  path {
    fill: ${({ theme }) => theme.palette.primary.main} !important;
  }
`
