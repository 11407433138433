import React from "react"
import * as S from "./info-card.styles"
import Arrow from "../../assets/arrow-right.svg"

const InfoCard = ({ icon, title, description, button }) => {
  return (
    <S.Wrapper url={button?.url} target={button.target}>
      <S.TopWrapper>
        <div>
          <S.Icon img={icon} />
          <S.Title>{title}</S.Title>
        </div>
        <S.Description>{description}</S.Description>
      </S.TopWrapper>
      <S.LinkWrapper>
        <S.Link>{button?.title}</S.Link>
        <Arrow />
      </S.LinkWrapper>
    </S.Wrapper>
  )
}

export default InfoCard
